import {Order} from "./Order.js";

export class OrderBuilder {

    /**
     * order configuration {Order}
     */
    order;

    constructor(orderId, amount) {
        this.order = new Order(orderId, amount, null, null, null);
    }

    /**
     * @param value {OrderLine[]}
     * @returns {OrderBuilder}
     */
    setOrderLines(value) {
        this.order.orderLines = value;
        return this;
    }

    /**
     * @param value {Customer}
     * @returns {OrderBuilder}
     */
    setCustomer(value) {
        this.order.customer = value;
        return this;
    }

    /**
     * @param value {Customer}
     * @returns {OrderBuilder}
     */
    setRecipient(value) {
        this.order.recipient = value;
        return this;
    }


    /**
     * @param value {Map<string, string>}
     * @returns {OrderBuilder}
     */
    setTransactionInfo(value) {
        this.order.transactionInfo = value;
        return this;
    }

    /**
     * Builds complete order object
     * @returns {Order}
     */
    build() {
        return this.order;
    }
}