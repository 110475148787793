import {AltaPayApplePay} from "./AltaPayApplePay.js";

/**
 * @class
 * @classdesc AltaPayPaymentMethodFactory is an object that provides helper functions to create payment specific JS handlers
 * @hideconstructor
 */
export class AltaPayPaymentMethodFactory {

    /**
     *
     * @param token
     * @param sessionId
     * @param paymentMethodId
     * @param order
     * @param data
     * @returns {AltaPayApplePay}
     * @constructor
     */
    ApplePay(token, sessionId, paymentMethodId, order, data) {
        return new AltaPayApplePay(token, sessionId, paymentMethodId, order, data);
    }

    /**
     *
     * @param token JWT token for the session
     * @param sessionId identifier for the checkout session
     * @param order order information, see {@link Order} for more details
     * @param method payment method information received from the server
     * @returns {IAltaPayMethodHandler}
     */
    createHandler(token, sessionId, order, method) {
        return this[method.jsMethodHandler.identifier](
            token,
            sessionId,
            method.id,
            order,
            method.jsMethodHandler.data
        );
    }
}