import {Amount} from "./Amount.js";
import {Customer} from "./Customer.js";
import {OrderLine} from "./OrderLine.js";

export class Order {
    /**
     *
     * @param orderId {string}
     * @param amount {Amount}
     * @param orderLines {OrderLine[]}
     * @param transactionInfo {Map<string, string> | null}
     * @param customer {Customer}
     * @constructor
     */
    constructor(orderId, amount, orderLines, customer, transactionInfo = null) {
        this._orderId = orderId;
        this._amount = amount;
        this._orderLines = orderLines;
        this._customer = customer;
        this._transactionInfo = transactionInfo;
    }

    get orderId() {
        return this._orderId;
    }

    set orderId(value) {
        this._orderId = value;
    }

    /**
     * @returns {Amount}
     */
    get amount() {
        return this._amount;
    }

    /**
     * @param value {Amount}
     */
    set amount(value) {
        this._amount = value;
    }

    /**
     *
     * @returns {OrderLine[]}
     */
    get orderLines() {
        return this._orderLines;
    }

    /**
     * @param value {OrderLine[]}
     */
    set orderLines(value) {
        this._orderLines = value;
    }

    /**
     *
     * @returns {Map<string, string> | null}
     */
    get transactionInfo() {
        return this._transactionInfo;
    }

    /**
     * @param value {Map<string, string> | null}
     */
    set transactionInfo(value) {
        this._transactionInfo = value;
    }

    /**
     * @returns {Customer}
     */
    get customer() {
        return this._customer;
    }

    /**
     * @param value {Customer}
     */
    set customer(value) {
        this._customer = value;
    }
    /**
     * @returns {Customer}
     */
    get recipient() {
        return this._recipient;
    }

    /**
     * @param value {Customer}
     */
    set recipient(value) {
        this._recipient = value;
    }

    serialize() {
        const serializedCustomer = this.customer ? this.customer.serialize() : null;
        const serializedRecipient = this.recipient ? this.recipient.serialize() : null;
        const orderLines = this.orderLines ? this.orderLines : [];
        const serializedAmount = this.amount? this.amount.serialize(): null;
        const transactionInfos = this.transactionInfo? this.transactionInfo : null;
        const order = {
            orderId: this.orderId,
            amount: serializedAmount,
            orderLines: [],
            customer: serializedCustomer,
            recipient: serializedRecipient,
            transactionInfo: transactionInfos
        };
        for (let i in orderLines) {
            let orderLine = this.orderLines[i];
            order.orderLines.push(orderLine.serialize());
        }

        return order;
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        let orderId = value.orderId ? value.orderId : null;
        let amount = value.amount ? Amount.fromJson(value.amount) : null;
        let customer = value.customer ? Customer.fromJson(value.customer) : null;
        let recipient = value.recipient ? Customer.fromJson(value.recipient) : null;
        let orderLines = [];
        let transactionInfo = value.transactionInfo ? value.transactionInfo : null;
        if(value.orderLines) {
            for (let i = 0; i < value.orderLines.length; i++) {
                const orderLine = value.orderLines[i];
                orderLines.push(OrderLine.fromJson(orderLine));
            }
        }
        const order = new Order(orderId, amount, orderLines, customer, transactionInfo);
        order.recipient = recipient;
        return order;
    }
}